.otp-input {
    width: 4rem;
    height: 4rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 2px;
    font-size: 1.01562rem;
    text-align: center;
    margin-right: 16px;
    border-width: 1px;
    border-style: solid;
    text-transform: uppercase;
    border-color: #ced4da;
    background-color: white;
}
@media (max-width: 1620px) {
    .otp-input {
        width: 3.5rem;
        height: 3.5rem;
    }
}
@media (max-width: 1500px) {
    .otp-input {
        width: 3.2rem;
        height: 3.2rem;
    }
}

@media (max-width: 1300px) {
    .otp-input {
        width: 3rem;
        height: 3rem;
        // margin-right: 0px;
    }
}

@media (max-width: 1100px) {
    .otp-input {
        width: 3rem;
        height: 3rem;
        margin-right: 0px;
    }
}
@media (max-width: 900px) {
    .otp-input {
        width: 2.5rem;
        height: 3rem;
        margin-right: 0px;
    }
}
@media (max-width: 767.99px) {
    .otp-input {
        width: 4rem;
        height: 4rem;
        margin-right: 16px;
    }
}

@media (max-width: 500px) {
    .otp-input {
        width: 3rem;
        height: 3rem;
        margin-right: 16px;
    }
}

